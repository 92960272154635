'use strict'

const validators = {
    'data-validation-equal-field': field => {
        const equalTarget = document.querySelector(field.getAttribute('data-validation-equal-field'))

        field.addEventListener('input', e => {
            equalTarget.value.toString().toLocaleLowerCase() !== e.target.value.toString().toLocaleLowerCase() ? field.setCustomValidity('qual-field') : field.setCustomValidity('')
            MyValidation.updateFieldStete(field)
        })

        equalTarget.addEventListener('input', e => {
            field.value.toString().toLocaleLowerCase() !== e.target.value.toString().toLocaleLowerCase() ? field.setCustomValidity('qual-field') : field.setCustomValidity('')
            MyValidation.updateFieldStete(field)
        })
    }
}

const MyValidation = {
    init(selector, options) {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll(selector)

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach((form) => {
                for (const validator in validators) {
                    Array.prototype.slice.call(form.querySelectorAll(`[${validator}]`))
                        .forEach(validators[validator])
                }

                this.addVlidationListners(form.elements)

                form.addEventListener('submit', event => {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                        this.checkFieldsState(form.elements)
                    } else {
                        this.onSubmit({ event, form, options })
                    }

                    form.classList.add('was-validated')
                }, false)
            })

        return this
    },

    addVlidationListners(elements) {
        Array.prototype.slice.call(elements)
            .forEach(field => {
                const target = field.closest('[data-validation-validate-class]')
                if (!target) return

                field.addEventListener('input', e => this.updateFieldStete(e.target))
                field.addEventListener('focus', e => target.classList.add('focus'))
                field.addEventListener('blur', e => target.classList.remove('focus'))
            })
    },

    checkFieldsState(elements) {
        Array.prototype.slice.call(elements)
            .forEach(field => this.updateFieldStete(field))
    },

    updateFieldStete(field) {
        const target = field.closest('[data-validation-validate-class]')
        if (!target) return

        if (field.checkValidity()) {
            //target.classList.add('is-valid')
            target.classList.remove('is-invalid')
        } else {
            target.classList.add('is-invalid')
            //target.classList.remove('is-valid')
        }
    },

    onSubmit(data) {
        if (!data.options || typeof data.options.onSubmit !== 'function') return false
        return data.options.onSubmit(data)
    }
}




        MyValidation.init('.js-validate', {
            onSubmit: data => {
                data.event.preventDefault()
                const formData = new FormData(data.event.target)
                const formProps = Object.fromEntries(formData)

                //   if (!ValidateEmail(formProps.businessemail)) {
                //     document.getElementById("formret").classList.remove('text-success')
                //     document.getElementById("formret").classList.add('text-danger')
                //     document.getElementById("formret").innerHTML = 'Invalid email address'
                //     return
                //   }

                //   if (!isCompanyEmail(formProps.businessemail)) {
                //     document.getElementById("formret").classList.remove('text-success')
                //     document.getElementById("formret").classList.add('text-danger')
                //     document.getElementById("formret").innerHTML = 'Must be Business email. @yourcompany.com'
                //     return
                //   }

                document.getElementById("btnsubmit").classList.remove('disabled')
                document.getElementById("btnsubmit").classList.add('disabled')

                document.getElementById("formret").innerHTML = 'Sending ...'

                fetch("/api/enterprise-contact", {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formProps)
                }).then(res => {
                    document.getElementById("formret").classList.remove('text-success')
                    document.getElementById("formret").classList.remove('text-danger')
                    document.getElementById("formret").classList.add('text-success')
                    if (res.status == 200) {
                        document.getElementById("formret").innerHTML = 'Thank you for contacting us. Our team will contact you shortly.'
                    } else {
                        document.getElementById("formret").classList.add('text-danger')
                        document.getElementById("btnsubmit").classList.remove('disabled')
                        document.getElementById("formret").innerHTML = 'Fail. Please try again.'
                    }

                }).catch((error) => {
                    document.getElementById("formret").classList.remove('text-success')
                    document.getElementById("formret").classList.add('text-danger')
                    document.getElementById("btnsubmit").classList.remove('disabled')
                    document.getElementById("formret").innerHTML = 'Fail. Please try again.'
                })

            }

        })

    